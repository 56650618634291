import { createSlice } from '@reduxjs/toolkit';
import { cofferApi } from '../../api/cofferApi';

const commercialOfferSlice = createSlice({
    name: 'commercialOffer',
    initialState: {        
        isCofferLoading: false,
        coffer: null,
        coffers: null,
        receiver: '',
        settings: null,
        isSettingsLoading: false
    },
    reducers: {        
        setCoffer: (state, action) => {
            state.coffer = action.payload;
        },
        setCoffers: (state, action) => {
            state.coffers = action.payload;
        },
        setCofferLoading: (state, action) => {
            state.isCofferLoading = action.payload;
        },
        setReceiver: (state, action) => {
            state.receiver = action.payload;
        },
        setSettingsLoading: (state, action) => {
            state.isSettingsLoading = action.payload;
        },
        setSettings: (state, action) => {
            state.settings = action.payload;
        }
    }
});

export const { setCofferLoading, setCoffer, setCoffers, setReceiver, setSettings, setSettingsLoading } = commercialOfferSlice.actions;


export const getCoffers = (page=1) => dispatch => {
    dispatch(setCofferLoading(true));
    cofferApi.getCoffers(page)
    .then(response => {
        if (response.status === 200) {
            dispatch(setCoffers(response.data));           
            dispatch(setCofferLoading(false));
        }
    })
    .catch(error => {
        dispatch(setCoffers(null));
        dispatch(setCofferLoading(false));
    })
}


export const getCoffer = (cartId) => dispatch => {
    dispatch(setCofferLoading(true));
    cofferApi.getCoffer(cartId)
    .then(response => {
        if (response.status === 200) {
            dispatch(setCoffer(response.data.coffer));
            dispatch(setReceiver(response.data.coffer.receiver));
            dispatch(setCofferLoading(false));
        }
    })
    .catch(error => {
        dispatch(setCoffer(null));
        dispatch(setReceiver(''));
        dispatch(setCofferLoading(false));
    })
}

export const updateCoffer = (cartId, receiver) => dispatch => {
    dispatch(setCofferLoading(true));
    cofferApi.updateCoffer(cartId, receiver)
    .then(response => {
        if (response.status === 200) {
            dispatch(setCoffer(response.data.coffer));
            dispatch(setReceiver(response.data.coffer.receiver));
            dispatch(setCofferLoading(false));
        }
    })
    .catch(error => {
        dispatch(setCoffer(null));
        dispatch(setReceiver(''));
        dispatch(setCofferLoading(false));
    })
}

export const createNewCoffer = (cartId) => dispatch => {
    dispatch(setCofferLoading(true));
    cofferApi.createNewCoffer(cartId)
    .then(response => {
        if (response.status === 200) {
            dispatch(setCoffer(response.data.coffer));
            dispatch(setReceiver(response.data.coffer.receiver));
            dispatch(setCofferLoading(false));
        }
    })
    .catch(error => {
        dispatch(setCoffer(null));
        dispatch(setReceiver(''));
        dispatch(setCofferLoading(false));
    })
}

export const getSettings = () => dispatch => {
    dispatch(setSettingsLoading(true));
    cofferApi.getSettings()
    .then(response => {
        if (response.status === 200) {
            dispatch(setSettings(response.data.settings));
            dispatch(setSettingsLoading(false));
        }
    })
    .catch(error => {
        dispatch(setSettings({}));
        dispatch(setSettingsLoading(false));
    })
}

export const updateProductCount = (cofferItemId) => dispatch => {
    dispatch(setCofferLoading(true));
    cofferApi.updateProductCount(cofferItemId)
    .then(response => {
        if (response.status === 200) {
            dispatch(setCoffer(response.data.coffer));
            dispatch(setReceiver(response.data.coffer.receiver));
            dispatch(setCofferLoading(false));
        }
    })
    .catch(error => {
        dispatch(setCoffer(null));
        dispatch(setReceiver(''));
        dispatch(setCofferLoading(false));
    })
}

export const deleteCofferItem = (cofferItemId) => dispatch => {
    dispatch(setCofferLoading(true));
    cofferApi.deleteCofferItem(cofferItemId)
    .then(response => {
        if (response.status === 200) {
            dispatch(setCoffer(response.data.coffer));
            dispatch(setReceiver(response.data.coffer.receiver));
            dispatch(setCofferLoading(false));
        }
    })
    .catch(error => {
        dispatch(setCoffer(null));
        dispatch(setReceiver(''));
        dispatch(setCofferLoading(false));
    })
}

export const updateCofferItems = (cofferId) => dispatch => {
    dispatch(setCofferLoading(true));
    cofferApi.updateCofferItems(cofferId)
    .then(response => {
        if (response.status === 200) {
            dispatch(setCoffer(response.data.coffer));
            dispatch(setReceiver(response.data.coffer.receiver));
            dispatch(setCofferLoading(false));
        }
    })
    .catch(error => {
        dispatch(setCoffer(null));
        dispatch(setReceiver(''));
        dispatch(setCofferLoading(false));
    })
}

export const selectCofferLoading = state => state.commercialOffer.isCofferLoading;
export const selectCoffer = state => state.commercialOffer.coffer;
export const selectCoffers = state => state.commercialOffer.coffers;

export const selectReceiver = state => state.commercialOffer.receiver;

export const selectSettings = state => state.commercialOffer.settings;
export const selectSettingsLoading = state => state.commercialOffer.isSettingsLoading;

export default commercialOfferSlice.reducer;