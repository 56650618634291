import { Button, Col, Row, Spin } from 'antd';
import Title from 'antd/lib/skeleton/Title';

import React, { useEffect, useRef } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import CofferId, { CofferIdStr } from '../../components/CofferId';
import FormattedNumber from '../../components/FormattedNumber';
import { selectCoffer, selectCofferLoading, getCoffer, selectSettings, selectSettingsLoading, getSettings } from '../../store/reducers/commercialOfferSlice';

const CommercialOfferPrint = (props) => {

    const cofferIdRef = useRef(null);

    const dispatch = useDispatch();

    const cartId = props.match.params.cartId;    

    const coffer = useSelector(selectCoffer);
    const isCofferLoading = useSelector(selectCofferLoading);

    const settings = useSelector(selectSettings);
    const isSettingsLoading = useSelector(selectSettingsLoading);

    useEffect(() => {
        dispatch(getCoffer(cartId));
        dispatch(getSettings());
    }, [cartId]);

    useEffect(() => {
        if (coffer) {
            document.title = CofferIdStr(coffer);
        }
    }, [coffer])

    let initialValue = 0;

    const productPriceSum = coffer.coffer_items.reduce((sum, item) => {
        return sum + (item.product.price * item.quantity);
    }, initialValue);

    let deliveryTermsType = 'in stock';
    coffer.coffer_items.forEach(item => {
        if (item.product.count < item.quantity) {
            deliveryTermsType = 'order';
        }
    })

    return (
        <>
            <Row gutter={16}>
                <Col span={24}>
                    {isCofferLoading || isSettingsLoading ? 
                        <Spin tip="Загрузка...">
                        </Spin>
                        :
                        <>
                        {coffer && settings ?
                            <div className='coffer_container'>
                                <div className='coffer_row space_between'>
                                    <div>
                                        <img src={settings.logo.value} className='coffer_logo'/>
                                    </div>
                                    <div className='coffer_column company_info'>
                                        <div>{settings.company_name.value}</div>
                                        <div>{settings.bin.represent}: {settings.bin.value}</div>
                                        <div>{settings.phone.represent}: {settings.phone.value}</div>
                                        <div>{settings.url.represent}: <a href={settings.url.value}>{settings.url.value}</a></div>
                                        <div>{settings.email.represent}: <a href={`mailto: ${settings.email.value}`}>{settings.email.value}</a></div>
                                    </div>
                                </div>
                                <div className='coffer_column coffer_title'>
                                    <div>
                                        <CofferId coffer={coffer}/>
                                    </div>
                                </div>
                                <div className='coffer_row'>
                                    <span className='coffer_greeting'>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{settings.greeting.value}
                                    </span>
                                </div>
                                <div className='coffer_column coffer_info'>
                                    <div>
                                        <span className='label'>Дата:</span> <Moment format="DD.MM.YYYY">{coffer.insert_date}</Moment>
                                    </div>
                                    <div>
                                        <span className='label'>От:</span> {settings.company_name.value}
                                    </div>
                                    <div>
                                        <span className='label'>Кому:</span> {coffer.receiver}
                                    </div>
                                </div>
                                <div className='coffer_row'>
                                    <table className='coffer_table'>
                                        <thead>
                                            <tr>
                                                <td>№</td>
                                                <td>P/N</td>
                                                <td>Наименование (Описание)</td>
                                                <td>Цена, тг.</td>
                                                <td>Шт</td>
                                                <td>Итого, тг</td>
                                                <td>Изображения</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {coffer && coffer.coffer_items.map((item, index) => (
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.product.sku.toUpperCase()}</td>
                                                    <td className='name_td'>
                                                        <div className='product_name'>{item.product.name}</div>
                                                        <div className='product_desc'>{item.product.desc}</div>
                                                    </td>
                                                    <td><FormattedNumber number={item.product.price}/></td>
                                                    <td>{item.quantity}</td>
                                                    <td><FormattedNumber number={item.quantity * item.product.price}/></td>
                                                    <td className='image_td'>
                                                        <img src={item.product.image_url} alt='изображение'/>
                                                        {item.product.count >= item.quantity ?
                                                            <div>В наличии</div>
                                                            :
                                                            <div>На заказ</div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan={5} className='sum_td'>Итого с учетом НДС:</td>
                                                <td colSpan={2} className='highlighted_text'><FormattedNumber number={productPriceSum}/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='coffer_row space_between'>
                                    <div className='coffer_column coffer_info'>
                                        <div>
                                            <span className='label'>{settings.payment_terms.represent}:</span> {settings.payment_terms.value}
                                        </div>
                                        {deliveryTermsType === 'in stock' ?
                                            <div>
                                                <span className='label'>{settings.delivery_time.represent}:</span> {settings.delivery_time.value}
                                            </div>
                                            :
                                            <div>
                                                <span className='label'>{settings.order_delivery_time.represent}:</span> {settings.order_delivery_time.value}
                                            </div>
                                        }
                                        <div>
                                            <span className='label'>{settings.warranty_terms.represent}:</span> {settings.warranty_terms.value}
                                        </div>
                                        <div>
                                            <span className='label'>{settings.delivery_terms.represent}:</span> {settings.delivery_terms.value}
                                        </div>
                                        <div>
                                            <span className='label'>{settings.delivery_terms_kz.represent}:</span> {settings.delivery_terms_kz.value}
                                        </div>
                                        <div className='coffer_row'>
                                            <span className='label'>По всем вопросам:&nbsp;</span><div dangerouslySetInnerHTML={{__html: settings.phone_numbers.value}}></div>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={settings.stamp.value} className='coffer_stamp'/>
                                    </div>
                                </div>
                                <div className='coffer_row'>
                                    <span className='coffer_time'>
                                        {settings.coffer_time.value}
                                    </span>
                                </div>
                                <Button className='print_button primary' onClick={() => window.print()}>Печать</Button>
                                <Button className='print_button primary' onClick={() => window.history.back()}>Назад</Button>
                            </div>
                            :
                            <div>
                                <Title>Нет ничего</Title>
                            </div>
                        }
                        </>
                    }
                </Col>
            </Row>
        </>
        
    )
}

export default CommercialOfferPrint;