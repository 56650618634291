import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout, message } from 'antd';

import CommercialOffer from '../pages/CommercialOffer/CommercialOffer';
import CommercialOfferPrint from '../pages/CommercialOffer/CommercialOfferPrint';
import CommercialOfferList from '../pages/CommercialOfferList/CommercialOfferList';
import CommercialOfferDetail from '../pages/CommercialOfferList/CommercialOfferDetail';
import DescriptionUpload from '../pages/DescriptionUpload/DescriptionUpload';
import StartPage from '../pages/startPage/StartPage';

const { Content } = Layout;

const Router = () => {
    return (
        <BrowserRouter>
            <Layout className='main_layout'>
                <Switch>
                    <Layout className='content_layout'>
                        <Content>
                            <Route exact path='/' component={StartPage}/>
                            <Route exact path='/commercial/:cartId' component={CommercialOffer}/>
                            <Route exact path='/commercial-print/:cartId' component={CommercialOfferPrint}/>
                            <Route exact path='/commercial-offers/' component={CommercialOfferList}/>
                            <Route exact path='/commercial-offers/:id' component={CommercialOfferDetail}/>
                            <Route exact path='/description-upload' component={DescriptionUpload}/>
                        </Content>
                    </Layout>
                </Switch>
            </Layout>
        </BrowserRouter>
    )
}

export default Router;