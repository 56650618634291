import React from 'react';

import 'antd/dist/antd.css';
import './styles/styles.scss';

import Router from './routes/Router';


function App() {
  return (
    <Router/>
  );
}

export default App;
