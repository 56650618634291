import React from 'react';
import Moment from 'react-moment';


const CofferId = ({coffer}) => {
    const formatCofferId = () => {
        const cofferZeroCount = 4 - coffer.doc_id.toString().length;

        return (
            <>
            {coffer.doc_id &&
                [...Array(cofferZeroCount)].map(zero => (
                    <>0</>
                ))
            }
            {coffer.doc_id}
            </>
        )
    }

    return (
        <>
            Коммерческое предложение № <Moment format="MM">{coffer.insert_date}</Moment>-{formatCofferId()}
        </>
    )
}

export const CofferIdStr = (coffer) => {
    
    
    const cofferZeroCount = 4 - coffer.doc_id.toString().length;
    let cofferIdStr = '';

    for (let i = 0; i < cofferZeroCount; i++) {
        cofferIdStr += '0';
    }

    cofferIdStr += coffer.doc_id;

    let cofferMonth = (new Date(coffer.insert_date).getMonth() + 1).toString();
    cofferMonth = cofferMonth.length > 1 ? cofferMonth : `0${cofferMonth}`;

    return `Коммерческое предложение № ${cofferMonth}-${cofferIdStr}`;
}

export const CofferIdStr2 = (coffer) => {
    
    
    const cofferZeroCount = 4 - coffer.doc_id.toString().length;
    let cofferIdStr = '';

    for (let i = 0; i < cofferZeroCount; i++) {
        cofferIdStr += '0';
    }

    cofferIdStr += coffer.doc_id;

    let cofferMonth = (new Date(coffer.insert_date).getMonth() + 1).toString();
    cofferMonth = cofferMonth.length > 1 ? cofferMonth : `0${cofferMonth}`;

    return `№ ${cofferMonth}-${cofferIdStr}`;
}

export default CofferId;