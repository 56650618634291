import { Col, Row, Spin, Button, Input } from 'antd';
import axios from 'axios';

import React, { useState } from 'react';
import { instance } from '../../api/api';


const DescriptionUpload = () => {
    const [file, setFile] = useState(null);

    const onFileChange = (event) => {
        setFile(event.target.files[0]);
        console.log(file);
    }

    const onFileUpload = () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file, file.name);
    
            instance.post(`export/upload-non-description-products/`, formData)
            .then(data => alert(data.data.status))
            .catch(error => alert(error));
        }
    }

    const onSkuFileUpload = () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file, file.name);
    
            instance.post(`export/upload-non-description-products-by-sku/`, formData)
            .then(data => alert(data.data.status))
            .catch(error => alert(error));
        }
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={24}>
                    <a href='https://its.rs6.kz/api/export/export-non-description-products'>Выгрузить товары без описаний</a>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <input type='file' onChange={onFileChange} />
                    <Button onClick={() => onFileUpload()}>Загрузить файл товаров с описаниями по ID</Button>
                    <Button onClick={() => onSkuFileUpload()}>Загрузить файл товаров с описаниями по артиклу</Button>
                </Col>
            </Row>
        </>
        
    )
}

export default DescriptionUpload;