import { Col, Row, Spin, Button, Input } from 'antd';
import Title from 'antd/lib/skeleton/Title';
import { EditOutlined, DeleteOutlined, RedoOutlined, PlusOutlined } from '@ant-design/icons';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CofferId from '../../components/CofferId';
import FormattedNumber from '../../components/FormattedNumber';
import { selectCoffer, selectCofferLoading, getCoffer, selectReceiver, setReceiver, updateCoffer, createNewCoffer, updateProductCount, updateCofferItems, deleteCofferItem } from '../../store/reducers/commercialOfferSlice';

const CommercialOffer = (props) => {

    const dispatch = useDispatch();

    const cartId = props.match.params.cartId;

    const coffer = useSelector(selectCoffer);
    const isCofferLoading = useSelector(selectCofferLoading);
    const receiver = useSelector(selectReceiver);

    const updateCofferReceiver = () => {
        dispatch(updateCoffer(cartId, receiver));
    }

    const createCoffer = () => {
        dispatch(createNewCoffer(cartId));
    }
    
    const updateProdCount = (cofferItemId) => {
        dispatch(updateProductCount(cofferItemId));
    }

    const deleteCofItem = (cofferItemId) => {
        dispatch(deleteCofferItem(cofferItemId));
    }

    const updateCofferItemList = (cofferId) => {
        dispatch(updateCofferItems(cofferId));
    }

    useEffect(() => {
        dispatch(getCoffer(cartId));
    }, [cartId]);

    return (
        <>
            <Row gutter={16}>
                <Col span={24}>
                    {isCofferLoading ? 
                        <Spin tip="Загрузка...">
                        </Spin>
                        :
                        <>
                        {coffer ?
                            <div style={{display: 'flex', flexDirection: 'row', gap: '10px', margin: '20px 0'}}>
                                <div>
                                    <CofferId coffer={coffer}/>
                                </div>
                                <div>
                                    <Button className='primary' onClick={() => createCoffer()}>Создать новое коммерческое предложение</Button>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                                    <label htmlFor="receiver">Кому: </label>
                                    <Input type="text" value={receiver} onChange={(e) => dispatch(setReceiver(e.target.value))}/>
                                    {coffer.receiver !== receiver &&
                                        <Button className='primary' onClick={() => updateCofferReceiver()}>Сохранить изменения</Button>
                                    }
                                </div>
                            </div>
                            :
                            <div>
                                <Title>Нет ничего</Title>
                            </div>
                        }
                        </>
                    }
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    {isCofferLoading ?
                        <Spin tip="Загрузка...">                        
                        </Spin>
                        :
                        <>
                            {coffer && 
                                <div className='coffer_row'>
                                    <table className='coffer_table'>
                                        <thead>
                                            <tr>
                                                <td>№</td>
                                                <td>P/N</td>
                                                <td>Наименование (Описание)</td>
                                                <td>Цена, тг.</td>
                                                <td>Шт</td>
                                                <td>Итого, тг</td>
                                                <td>Изображения</td>
                                                <td>Редактировать</td>
                                                <td>Обновить наличие</td>
                                                <td>Удалить</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {coffer && coffer.coffer_items.map((item, index) => (
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.product.sku.toUpperCase()}</td>
                                                    <td className='name_td'>
                                                        <div className='product_name'>{item.product.name}</div>
                                                        <div className='product_desc'>{item.product.desc}</div>
                                                    </td>
                                                    <td><FormattedNumber number={item.product.price}/></td>
                                                    <td>{item.quantity}</td>
                                                    <td><FormattedNumber number={item.quantity * item.product.price}/></td>
                                                    <td className='image_td'>
                                                        <img src={item.product.image_url} alt='изображение'/>
                                                        <div>Количество на складе: {item.product.count}</div>
                                                    </td>
                                                    <td className='button_td'>
                                                        <a href={`https://its.rs6.kz/admin/coffer/cofferitem/${item.id}/change/`} target='_blank' rel='noreferrer'>
                                                            <Button className='secondary' icon={<EditOutlined />}>
                                                            </Button>
                                                        </a>
                                                        
                                                    </td>
                                                    <td className='button_td'>
                                                        {item.product.product_id ?
                                                            <Button className='secondary' icon={<RedoOutlined />} onClick={() => updateProdCount(item.id)}>
                                                            </Button>
                                                            :
                                                            <Button className='disabled' icon={<RedoOutlined />}>                                                            
                                                            </Button>
                                                        }
                                                        
                                                    </td>
                                                    <td className='button_td'>
                                                        <Button type='danger' icon={<DeleteOutlined />} onClick={() => deleteCofItem(item.id)}>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}                                            
                                        </tbody>
                                    </table>
                                </div>
                            }
                            <div style={{marginTop: '20px', display: 'flex', flexDirection: 'row', gap: '10px'}}>
                                <Button className='primary'>
                                    <Link to={`/commercial-print/${cartId}`}>PDF</Link>
                                </Button>
                                <a href='https://its.rs6.kz/admin/coffer/cofferitem/add/' target='_blank' rel='noreferrer'>
                                    <Button className='primary' icon={<PlusOutlined />}>
                                        Добавить товар
                                    </Button>
                                </a>
                                <Button className='primary' icon={<RedoOutlined />} onClick={() => updateCofferItemList(coffer.id)}>
                                    Обновить товары из корзины
                                </Button>
                            </div>
                        </>
                    }
                    
                </Col>
            </Row>
        </>
        
    )
}

export default CommercialOffer;