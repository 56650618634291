import React from 'react';

const StartPage = () => {
    return (
        <>
            HELLO
        </>
    )
}

export default StartPage;