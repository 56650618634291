import { Col, Row, Spin, Button, Input } from 'antd';
import Title from 'antd/lib/skeleton/Title';
import { EditOutlined, DeleteOutlined, RedoOutlined, PlusOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCoffers, selectCofferLoading, selectCoffers } from '../../store/reducers/commercialOfferSlice';
import CofferId, { CofferIdStr2 } from '../../components/CofferId';
import Moment from 'react-moment';

const CommercialOfferList = () => {

    const dispatch = useDispatch();

    const coffers = useSelector(selectCoffers);
    const isCofferLoading = useSelector(selectCofferLoading);

    useEffect(() => {
        dispatch(getCoffers());
    }, []);

    const getPreviousCoffers = () => {
        const previous = coffers.previous;

        if (previous) {
            const splitted = previous.split('?page=');
            let pageNumber;
            if (splitted.length > 1) {
                pageNumber = splitted.pop();
            }
            else {
                pageNumber = 1;
            }
            
            dispatch(getCoffers(pageNumber));
        }
    }

    const getNextCoffers = () => {
        const next = coffers.next;

        if (next) {
            const pageNumber = next.split('?page=').pop();
            dispatch(getCoffers(pageNumber));
        }
    }

    return (        
        <Row gutter={16}>
            <Col span={24}>
                {isCofferLoading ? 
                    <Spin tip="Загрузка...">
                    </Spin>
                    :
                    <>
                        {coffers && 
                            <>
                                <div className='coffer_row' style={{marginTop: '20px'}}>
                                    <table className='coffer_table'>
                                        <thead>
                                            <tr>
                                                <td>№</td>
                                                <td>ID</td>
                                                <td>Кому</td>
                                                <td>Список товаров</td>
                                                <td>Дата создания</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {coffers.results.map((item) => (
                                                <tr>
                                                    <td>{item.id}</td>
                                                    <td><Link to={`/commercial-offers/${item.id}`}>{CofferIdStr2(item)}</Link></td>
                                                    <td>{item.receiver}</td>
                                                    <td>
                                                        {item.coffer_items.map((coff_item, idx) => (
                                                            <div>{idx+1}.{coff_item.product.name}</div>
                                                        ))}
                                                    </td>
                                                    <td><Moment format='DD.MM.YYYY'>{item.insert_date}</Moment></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='coffer_row' style={{marginTop: '20px'}}>
                                    {coffers.previous ?
                                        <Button className='primary' icon={<ArrowLeftOutlined />} onClick={() => getPreviousCoffers()}/>
                                        :
                                        <Button className='disabled' icon={<ArrowLeftOutlined />}/>
                                    }
                                    {coffers.next ?
                                        <Button className='primary' icon={<ArrowRightOutlined />} onClick={() => getNextCoffers()}/>
                                        :
                                        <Button className='disabled' icon={<ArrowRightOutlined />}/>
                                    }
                                </div>
                            </>
                        }
                    </>
                }
            </Col>
        </Row>
    )
}

export default CommercialOfferList;