import { instance } from "./api";

export const cofferApi = {
    getCartItems(cartId) {
        return instance.get(`coffer/cart_items/${cartId}/`);
    },
    getCoffer(cartId) {
        return instance.get(`coffer/coffer/${cartId}/`);
    },
    updateCoffer(cartId, receiver) {
        return instance.put(`coffer/coffer/${cartId}/`, {receiver});
    },
    createNewCoffer(cartId) {
        return instance.post(`coffer/coffer/${cartId}/`);
    },
    getSettings() {
        return instance.get('coffer/settings');
    },
    updateProductCount(cofferItemId) {
        return instance.put(`coffer/coffer_item/${cofferItemId}/`);
    },
    deleteCofferItem(cofferItemId) {
        return instance.delete(`coffer/coffer_item/${cofferItemId}/`);
    },
    updateCofferItems(cofferId) {
        return instance.put(`coffer/coffer_items/${cofferId}/`);
    },
    getCoffers(page) {
        return instance.get(`coffer/coffers/?page=${page}`);
    }
}