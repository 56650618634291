import { configureStore } from '@reduxjs/toolkit';


import commercialOfferReducer from './reducers/commercialOfferSlice';

export default configureStore({
    reducer: {
        commercialOffer: commercialOfferReducer
    }
});


